<template>
  <div class="single-customer shadow-sm">
    <div class="upper-block">
      <div class="rider-profile">
        <div class="user-block">
          <div class="user-image" style="position: relative">
            <img
              :src="
                singleCustomer !== undefined && singleCustomer.profileImage
                  ? singleCustomer.profileImage
                  : '/media/users/user1.jpg'
              "
              alt="user profile image"
            />
          </div>
          <div class="user-name">
            {{
              singleCustomer !== undefined && singleCustomer.name
                ? singleCustomer.name
                : "..."
            }}
          </div>
        </div>
        <div class="info-rating-block">
          <span class="info-heading">Rating</span>
          <div class="info">
            <span class="ratings-block">
              <i
                v-for="index in 5"
                class="fa fa-star"
                v-bind:class="getGoldClass(rating, index)"
                :key="index"
              ></i>
            </span>
            <span style="margin-left: 2.5px">{{ rating }}</span>
          </div>
        </div>
      </div>
      <div class="rider-info">
        <div class="info-blocks">
          <div class="info-block">
            <div class="info">
              <div class="heading">Customer Address</div>
              <div class="text">
                {{
                  singleCustomer !== undefined &&
                  singleCustomer.shippingAddress != undefined
                    ? singleCustomer.shippingAddress.address
                    : "No Address Added"
                }}
              </div>
            </div>
            <div class="info">
              <div class="heading">Zip Code</div>
              <div class="text">
                {{
                  singleCustomer !== undefined && singleCustomer.zipcode
                    ? singleCustomer.zipcode
                    : 0
                }}
              </div>
            </div>
            <div class="info">
              <div class="heading">Phone</div>
              <div class="text">
                {{
                  singleCustomer !== undefined && singleCustomer.phone
                    ? singleCustomer.phone
                    : "No phone added"
                }}
              </div>
            </div>
            <div class="info">
              <div class="heading">Email</div>
              <div class="text">
                {{
                  singleCustomer !== undefined && singleCustomer.email
                    ? singleCustomer.email
                    : "..."
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="performance-block">
      <div class="list-block shadow-sm">
        <ul class="details-list head">
          <li class="details-list-item">Orders</li>
          <li class="details-list-item">Total Revenue</li>
          <li class="details-list-item">Avg Baskyt Size</li>
        </ul>
        <ul class="details-list">
          <li class="details-list-item">
            {{
              singleCustomer !== undefined &&
              singleCustomer.totalOrders != undefined
                ? singleCustomer.totalOrders
                : "0"
            }}
          </li>
          <li class="details-list-item time">
            {{
              singleCustomer !== undefined &&
              singleCustomer.totalRevenue != undefined
                ? "$" + singleCustomer.totalRevenue
                : "$0"
            }}
          </li>
          <li class="details-list-item">
            <!-- {{
              customer !== undefined && customer.zipCodes
                ? customer.zipCodes.length
                : "0"
            }} -->
            0
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    singleCustomer: Object
  },

  data() {
    return {
      rating: 0
    };
  },
  mounted() {
    if (
      this.singleCustomer !== undefined &&
      this.singleCustomer.avgRating !== undefined
    ) {
      this.rating =
        this.singleCustomer.avgRating % 1 != 0
          ? this.singleCustomer.avgRating
          : this.singleCustomer.avgRating + ".0";
    } else {
      this.rating = 0;
    }
  },
  methods: {
    getGoldClass: function(total, key) {
      return key <= total ? "gold" : "";
    }
  }
};
</script>

<style lang="scss" scoped>
.single-customer {
  @media only screen and (max-width: 1366px) {
    width: 495px;
  }
  width: 525px;
  width: 100%;
  background: #fff;
  border-radius: 15px;

  position: relative;
  margin: 10px;
  overflow: hidden;

  .upper-block {
    transition: all 400ms;
    display: flex;
    padding: 15px;
    padding-top: 35px;
    position: relative;
    .approved-status {
      position: absolute;
      top: 0;
      left: 0;
      padding: 5px;
      border-bottom-right-radius: 5px;
      &.approved {
        background: #6dff4c;
        color: #fff;
      }
      &.non-approved {
        background: #f8b040;
        color: #fff;
      }
    }
    .rider-profile {
      width: 25%;
    }
    .rider-info {
      width: 75%;
      .info-blocks {
        display: grid;
        grid-template-columns: auto;
        .info-block {
          font-size: 12px;
          display: flex;
          flex-direction: column;
          padding: 2.5px 5px;

          .info {
            padding: 5px 0;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            .heading {
              flex-shrink: 2;
              font-weight: 600;
            }
            .text {
              padding-left: 5px;
              text-align: right;
              flex-shrink: 3;
            }
          }
        }
      }
    }
  }
  .rider-profile {
    .user-block {
      display: flex;
      align-items: center;

      width: 100%;
      padding: 0;
      flex-direction: column;
      .user-image {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        img {
          height: 55px;
          width: 55px;
          border-radius: 50%;
        }
        .online-status {
          position: absolute;
          height: 18px;
          width: 18px;
          bottom: -3px;
          right: -2px;
          border: 3px solid #fff;
          border-radius: 50%;
          &.online {
            background: #00bc00;
          }
          &.offline {
            background: #d8102a;
          }
        }
      }
      .user-name {
        width: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
        font-size: 14px;
      }
    }
    .info-rating-block {
      display: flex;
      flex-direction: column;
      .info-heading {
        font-size: 12px;
        text-transform: uppercase;
        color: #ddd;
        padding: 5px 0;
      }
      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        font-weight: 700;
        .ratings-block {
          i.gold {
            color: #ff9500;
          }
        }
      }
    }
  }

  .performance-block {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    transition: all 400ms;
    .performance-header {
      background: #4e71e1;
      text-align: center;
      color: #fff;
      text-transform: capitalize;
      padding: 15px;
      font-size: 16px;
    }
    .list-block {
      width: 100%;
      margin-bottom: 15px;
      ul.details-list {
        list-style: none;
        width: 100%;
        padding: 10px;
        display: flex;
        margin-bottom: 0;
        &.head {
          font-weight: 700;
        }
        li.details-list-item {
          width: 33%;
          text-align: center;
        }
      }
    }
  }
}
</style>
