<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6 py-3">
          <h3>Customers</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-4 sorting-options">
          <b-form-group label="Sort Users By">
            <b-form-radio-group
              class="option-styles"
              v-model="sortCustomers"
              :options="sortCustomersOptions"
              name="radio-inline"
              @change="changeSort"
            ></b-form-radio-group>
          </b-form-group>
        </div>
        <div
          class="col-4 d-flex flex-column justify-content-between align-items-center"
        >
          <b-form-group>
            <b-form-radio-group
              id="btn-radios-1"
              v-model="sortDirection"
              :options="sortDirectionOptions"
              buttons
              name="radios-btn-default"
              @change="changeDirection"
            ></b-form-radio-group>
          </b-form-group>
          <div class="row d-flex align-items-center">
            <div class="col-9">
              <b-input
                type="text"
                v-model="searchText"
                placeholder="Search Customer"
              />
            </div>
            <div class="col-3">
              <button class="go-btn" @click="searchCustomers">Go</button>
            </div>
          </div>
        </div>
        <div
          class="col-4 d-flex align-items-center justify-content-end py-3"
          v-if="count > 10"
        >
          <div class="row w-100">
            <span class="col-4 d-flex align-items-center"
              >Customers Per Page</span
            >
            <b-form-select
              v-model="perPage"
              :options="perPageOptions"
              class="col-8"
              @change="perPageChange"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>

    <div class="customers-section">
      <div class="overlay" v-if="fetching">
        <div class="d-flex justify-content-center">
          <b-spinner variant="danger"></b-spinner>
          <span class="ml-4">Fetching...</span>
        </div>
      </div>
      <b-row class="w-100">
        <template v-if="!fetching && networkError === null">
          <b-col
            cols="12"
            md="6"
            class="d-flex"
            :key="customer.updatedAt"
            v-for="customer in customers"
          >
            <SingleCustomer :singleCustomer="customer" />
          </b-col>
        </template>
        <template v-else-if="!fetching && networkError !== null">
          <b-col cols="12">
            <div class="d-flex flex-column align-items-center w-100">
              <div class="text-danger text-center display-4 p-4">API Error</div>
              <div class="text-danger text-center lead pb-4">
                Error while fetchting customers. Please refresh to try again.
              </div>
              <div class="text-danger text-center lead pb-4">
                {{ networkError }}
              </div>
            </div>
          </b-col>
        </template>
      </b-row>
    </div>
    <div class="d-flex justify-content-center">
      <b-pagination
        v-model="currentPage"
        :total-rows="count"
        :per-page="perPage"
        first-number
        @change="changePage"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import { FETCH_CUSTOMERS } from "@/core/services/store/customers.module";
import SingleCustomer from "@/view/pages/customers/SingleCustomer";
import { mapGetters, mapState } from "vuex";
export default {
  name: "CustomersComponent",
  components: {
    SingleCustomer
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" }
      ],
      sortCustomers: "sortByAvgRating",
      sortCustomersOptions: [
        // { text: "Created", value: "sortByCreatedDate" },
        { text: "Total Orders", value: "sortByTotalOrders" },
        { text: "Zip Code", value: "sortByZipCode" }
      ],
      sortDirection: 1,
      sortDirectionOptions: [
        {
          text: "Ascending",
          value: 2,
          html: '<i class="fa fa-sort-amount-up"/>'
        },
        {
          text: "Descending",
          value: 1,
          html: '<i class="fa fa-sort-amount-down"/>'
        }
      ],
      searchText: ""
    };
  },
  mounted() {
    this.$store.dispatch(FETCH_CUSTOMERS);
  },
  methods: {
    ...mapGetters(["getCustomers"]),
    changeSort: function(val) {
      let obj = {};
      obj[val] = this.sortDirection;
      obj.searchText = this.searchText;
      this.updateCustomers(obj);
    },
    searchCustomers: function() {
      let obj = {};
      obj[this.SortCustomers] = this.sortDirection;
      obj.searchText = this.searchText;
      this.updateCustomers(obj);
    },
    changeDirection: function(val) {
      let obj = {};
      obj[this.SortCustomers] = val;
      obj.searchText = this.searchText;
      this.updateCustomers(obj);
    },
    updateCustomers(options = {}) {
      options.limit = [
        (this.currentPage - 1) * this.perPage,
        (this.currentPage - 1) * this.perPage + this.perPage
      ];
      this.$store.dispatch(FETCH_CUSTOMERS, options);
    },
    changePage() {
      let obj = {};
      obj[this.SortCustomers] = this.sortDirection;
      obj.searchText = this.searchText;
      this.updateCustomers(obj);
    },
    perPageChange() {
      let obj = {};
      obj[this.sortActiveRiders] = this.sortDirection;
      obj.searchText = this.searchText;
      this.updateCustomers(obj);
    }
  },
  computed: {
    ...mapState({
      customers: function(state) {
        return state.customers.customers;
      },
      count: function(state) {
        return state.customers.customersCount;
      },
      fetching: function(state) {
        return state.customers.fetchingCustomers;
      },
      networkError: function(state) {
        return state.customers.fetchingError;
      }
    })
  }
};
</script>

<style lang="scss" scoped>
.customers-section {
  width: 100%;
  min-height: 75px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .overlay {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 25px;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
.go-btn {
  color: #fff;
  background: #d8102a;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 5px;
}
.sorting-options {
  .option-styles {
    display: grid;
    grid-template-columns: auto auto;
  }
  ::v-deep legend.col-form-label {
    font-weight: 600;
  }
}
</style>
